import React from 'react';

import Mozhi from '../../context/Mozhi';
import DeviceContext from '../../context/DeviceContext';
import { useContext } from 'react';
import { getNavigationItems } from '../../constants';
import getLang from '../../utils/lang';
import style from './Header.module.css';

function HeaderModal({ setIsBookNowOpen, setIsMenuOpen }) {
  const { lang, setLang } = useContext(Mozhi);
  const { isMini } = useContext(DeviceContext);
  const navigationItems = getNavigationItems(lang);

  // events
  const handleLangChange = () => {
    setLang((pre) => !pre);
  };

  const handleHome = () => {
    setIsMenuOpen(false);
  };

  return (
    <div
      className="btn"
      style={{
        textAlign: 'center',
        height: '80vh',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        background: 'linear-gradient(145deg, #f6ffff, #cfd8e0)',
        boxShadow:
          '-6px -2px 16px rgba(255, 255, 255, 0.4), 6px 2px 16px rgba(19, 23, 27, 0.4)',
        borderRadius: '20px',
        overflow: 'hidden',
      }}
    >
      <a href="#main" style={{ margin: '0px 20px' }} onClick={handleHome}>
        <img src="img/company-logo.webp" className={style.logo} alt="logo" />
        <h3 className={style.title}>{getLang(lang, 'company.name')}</h3>
      </a>
      <div style={{ display: 'block', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: '15px',
          }}
        >
          <button
            className={`btn-default inline-block ${style.lang} pointer`}
            style={{
              backgroundColor: 'rgba(112, 78, 225, 0.5)',
              // margin: '20px 40px',
              display: isMini ? 'inline-block' : undefined,
            }}
            onClick={handleLangChange}
          >
            {getLang(lang, 'lang.change.button')}
          </button>
          {/* CTA */}
          <button
            className={`btn-default inline-block pointer ${style.secondary} ${style.bookNow}`}
            onClick={() => setIsBookNowOpen(true)}
          >
            {getLang(lang, 'book.now')}
          </button>
        </div>

        {navigationItems.map(({ id, href }) => (
          <a
            href={href}
            onClick={() => setIsMenuOpen(false)}
            className="btn-default hover-btn pointer"
            style={{
              display: 'block',
              margin: '30px',
              textDecoration: 'none',
              color: 'var(--text-primary)',
            }}
          >
            {id}
          </a>
        ))}
      </div>
    </div>
  );
}

export default HeaderModal;
