import React from 'react';
import getLang from '../../utils/lang';
import style from '../Header/Header.module.css';

import Mozhi from '../../context/Mozhi';
import { useContext } from 'react';
import { getNavigationItems } from '../../constants';

function HeaderList() {
  const { lang } = useContext(Mozhi);

  const navigationItems = getNavigationItems(lang);
  return (
    <div className={`btn-default ${style.headerList}`}>
      {navigationItems.map((item) => (
        <span key={item.id}>
          <a href={item.href} className={`${style.headerLink}`}>
            {item.id}
          </a>
        </span>
      ))}
    </div>
  );
}

export default HeaderList;
