import React from 'react';

// style
import style from './SocialLinks.module.css';

function SocialLinks(props) {
  const { isCenter } = props;

  return (
    <div
      className={style.container}
      style={{ justifyContent: isCenter ? 'center' : 'left' }}
    >
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/shanmugadeivam"
      >
        <img src="/icons/facebook.svg" className={style.items} alt="facebook" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/deivammahal/"
      >
        <img
          src="/icons/instagram.svg"
          className={style.items}
          alt="instagram"
        />
      </a>
      {/* <a href="">
        <img
          src="/icons/telephone.svg"
          className={style.items}
          alt="telephone"
        />
      </a> */}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://twitter.com/DeivamMahal"
      >
        <img src="/icons/twitter.svg" className={style.items} alt="twitter" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.youtube.com/channel/UCyBXCT9Psu6bsrgUcKjuHqA"
      >
        <img src="/icons/youtube.svg" className={style.items} alt="youtube" />
      </a>
    </div>
  );
}

export default SocialLinks;
