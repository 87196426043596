import getLang from '../utils/lang';

const getNavigationItems = (lang) => [
  {
    id: getLang(lang, 'features'),
    href: '#features',
  },
  // {
  //   id: 'about us',
  //   href: '#aboutus',
  // },
  {
    id: getLang(lang, 'events'),
    href: '#events',
  },
  // {
  //   id: 'gallery',
  //   href: '#gallery',
  // },
  {
    id: getLang(lang, 'contact'),
    href: '#contact',
  },
  // {
  //   id: 'testimonials',
  //   href: '#testimonials',
  // },
];

export { getNavigationItems };
