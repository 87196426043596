import React from 'react';
import FeaturesContent from '../FeaturesContent/FeaturesContent';

function FeaturesContainer() {
  return (
    <div>
      <FeaturesContent />
    </div>
  );
}

export default FeaturesContainer;
