import React from 'react';
import getLang from '../../../utils/lang';

import Mozhi from '../../../context/Mozhi';
import { useContext } from 'react';
import data from '../../../data';
import SocialLinks from '../../SocialLinks/SocialLinks';

import style from './BookNow.module.css';

function BookNow() {
  const { lang } = useContext(Mozhi);
  return (
    <div
      style={{
        textAlign: 'center',
        height: '80vh',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className={style.btn}
    >
      <div style={{ transform: 'translateY(-10%)' }}>
        <h1>{getLang(lang, 'contact')}</h1>
        <h2>{data.PHONE_NUMBER}</h2>
        <h2>{data.PHONE_NUMBER_2}</h2>
        <h2>{data.MAIL}</h2>
        <SocialLinks isCenter />
      </div>
    </div>
  );
}

export default BookNow;
