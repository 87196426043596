import React from 'react';
import getLang from '../../utils/lang';
import SocialLinks from '../SocialLinks/SocialLinks';

import Mozhi from '../../context/Mozhi';
import { useContext } from 'react';

function FooterContainer() {
  const { lang } = useContext(Mozhi);
  return (
    <div
      style={{
        backgroundColor: 'black',
        margin: '0px',
        padding: '50px',
        textAlign: 'center',
      }}
    >
      <h3 style={{ color: 'white' }}>{getLang(lang, 'company.name')}</h3>
      <p style={{ color: 'white' }}>{getLang(lang, 'company.description')}</p>
      <div style={{ textAlign: 'center' }}>
        <SocialLinks isCenter />
      </div>
    </div>
  );
}

export default FooterContainer;
