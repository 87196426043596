const english = {
  // MAIN PAGE

  'company.name': 'சண்முகதெய்வம் மஹால்',
  'company.description': 'உங்களுடைய குடும்ப நிகழ்வுகளுக்கு சிறந்த இடம்.',
  features: 'அம்சங்கள்',
  aboutus: 'எங்களை பற்றி',
  events: 'நிகழ்வுகள்',
  gallery: 'கேலரி',
  contact: 'தொடர்பு',
  testimonials: 'சான்றுகள்',
  'lang.change.button': 'Eng Version',
  'book.now': 'பதிவு செய்ய ',
  menu: 'பட்டியல்',

  // FEATURES PAGE

  'features.desc':
    'எல்லா வகையான நிகழ்வுகளும் இங்கே பதிவு செய்யப்படலாம்.திருமணத்திலிருந்து, அதிகாரப்பூர்வ சந்திப்பில் இருந்து கட்சி முதலியன',
  feature1: 'மரங்கள் சூழப்பட்ட மஹால்',
  'feature1.desc':
    'எங்கள் மஹால் உணரக்கூடிய தனித்துவமான அம்சம் மரங்கள் மற்றும் தூய காற்று கொண்ட இயற்கை சூழலாகும்.',
  feature2: 'இயல்பு உணர்கிறேன்',
  'feature2.desc':
    'நகர்ப்புற நகரத்திலிருந்து விலகி, மாசுபாடு இல்லை, இல்லை அடர்த்தியான போக்குவரத்து மற்றும் எனவே எந்த கவலையும் இல்லை.ஆனால் போக்குவரத்து சுலபமாக இல்லை. ',
  feature3: 'வெளிப்புற விழா',
  'feature3.desc':
    'ஒரு வெளிப்புற விழாவாக ஒரு நிகழ்வைக் கொண்டிருப்பதாக எப்போதும் கனவு கண்டீர்களா?வரவேற்பு, கட்சிகள் மற்றும் பாலைவன உணவு மற்றும் இன்னும் திறந்த நிலை உள்ளது...',
  feature4: 'நல்ல பார்க்கிங் இடம்',
  'feature4.desc':
    'நாங்கள் கார் மற்றும் பைக் பார்க்கிங் எங்கள் சொந்த அதிநவீன இடம் உள்ளது.அங்கு எந்த வம்பு இருக்க முடியாது.',
  feature5: 'பயனுள்ள விஷயங்கள்',
  'feature5.desc': 'அனைவருக்கும் பயனளிக்கும் திட்டங்களை நாங்கள் செய்தோம்.',
  feature6: 'மற்றும் இன்னும் பல...',
  'feature6.desc': 'ஒரு வருகை மற்றும் எங்கள் சூழலை அனுபவிக்க தயங்க.',

  // COUNTS PAGE
  'count.header': 'சில விஷயங்கள் முக்கியம்',
  'count.desc': 'எண்கள் சித்தரிக்கப்பட்ட பயணத்தில் எங்கள் சாதனை',
  'mahal.capacity': 'அன்பே திறன்.',
  'dining.hall.seats': 'டைனிங் ஹால் இடங்கள்',
  'mahal.sqft': '(SQFT) மஹால் பரிமாணங்கள்',
  'mahal.events.successfully': 'நிகழ்வுகள் வெற்றிகரமாக',

  // TYPES OF EVENTS PAGE
  'event.heading': 'பல்நோக்கு அன்பே',
  'event.wedding': 'திருமண மற்றும் பிற பாரம்பரிய நிகழ்வுகள்',
  'event.wedding.desc': 'வரவேற்பு, நிச்சயதார்த்தம்',
  'event.party': 'கட்சி',
  'event.party.desc':
    'பிறந்தநாட்கள், கூட்டாளிகள், பிரியாவிடை, பெருநிறுவன கட்சி கிடைக்கும்',
  'event.business.fairs': 'வணிக விழாக்கள்',
  'event.business.fairs.desc':
    'புத்தக சிகப்பு, மரச்சாமான்கள் சிகப்பு, மின்னணுவியல் சிகப்பு',
  'event.business.events': 'வணிக நிகழ்வுகள் ',
  'event.business.events.desc': 'சந்திப்பு, கருத்தரங்குகள், மாநாடு',
  'event.dining': 'பிரீமியம் உணவு',
  'event.dining.desc': 'வேடிக்கை நிகழ்வுகள் மற்றும் டி.ஜே..',

  // CONTACTS PAGE
  'contact.name': 'பெயர்',
  'contact.mail': 'மின்னஞ்சல்',
  'contact.any.to.say': 'சொல்ல ஏதாவது...',
  'contact.submit': 'சமர்ப்பிக்கவும்',
  'cta.text': `உங்கள் நிகழ்வை மறக்கமுடியாத ஒன்றாக மாற்றுவோம்`,
  location: 'இருப்பிடம்',
  'contact.us': 'எங்களை தொடர்பு கொள்ள',
  documentTitle: 'சண்முகதெய்வம் மஹால் | மதுரை',
};

export default english;
