import React from 'react';
import getLang from '../../../utils/lang';
import FeaturesItems from '../FeaturesItems/FeaturesItems';

import style from './FeaturesList.module.css';

import Mozhi from '../../../context/Mozhi';
import { useContext } from 'react';

const iconDirectory = '/icons/';
const ext = '.svg';

function FeaturesList() {
  const { lang } = useContext(Mozhi);
  const features = [
    {
      icon: `${iconDirectory}trees${ext}`,
      heading: getLang(lang, 'feature1'),
      description: getLang(lang, 'feature1.desc'),
    },
    {
      icon: `${iconDirectory}leaf${ext}`,
      heading: getLang(lang, 'feature2'),
      description: getLang(lang, 'feature2.desc'),
    },
    {
      icon: `${iconDirectory}forest${ext}`,
      heading: getLang(lang, 'feature3'),
      description: getLang(lang, 'feature3.desc'),
    },
    {
      icon: `${iconDirectory}parking${ext}`,
      heading: getLang(lang, 'feature4'),
      description: getLang(lang, 'feature4.desc'),
    },
    {
      icon: `${iconDirectory}salary${ext}`,
      heading: getLang(lang, 'feature5'),
      description: getLang(lang, 'feature5.desc'),
    },
    {
      icon: `${iconDirectory}smile${ext}`,
      heading: getLang(lang, 'feature6'),
      description: getLang(lang, 'feature6.desc'),
    },
  ];
  return (
    <div className={style.container}>
      {features.map((feature) => (
        <FeaturesItems {...feature} key={feature.icon} />
      ))}
    </div>
  );
}

export default FeaturesList;
