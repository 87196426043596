import React from 'react';
import EventsHeader from '../EventsHeader/EventsHeader';
import EventsList from '../EventsList/EventsList';

function EventsContainer() {
  return (
    <div>
      <EventsHeader />
      <EventsList />
    </div>
  );
}

export default EventsContainer;
