export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function getDeviceStyle(device, styles, unit = '') {
  const value = () => {
    switch (device) {
      case 'mini':
        return styles[0];
      case 'mobile':
        return styles[1];
      case 'tab':
        return styles[2];
      case 'laptop':
        return styles[3];
      case 'desktop':
        return styles[4];
      case 'tv':
        return styles[5];
      default:
        return styles[0];
    }
  };

  return value() + unit;
}
