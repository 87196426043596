import React from 'react';
import getLang from '../../../utils/lang';
import style from './ContactRightPanel.module.css';
import Mozhi from '../../../context/Mozhi';
import { useContext } from 'react';
import data from '../../../data';
import SocialLinks from '../../SocialLinks/SocialLinks';

function ContactRightPanel() {
  const { lang } = useContext(Mozhi);
  return (
    <div className={style.container}>
      <h1 id="contact">{getLang(lang, 'contact.us')}</h1>
      <div
        className="btn-default"
        style={{
          height: '450px',
          width: '90%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          textAlign: 'center',
        }}
      >
        <div className={style.box}>
          {/* <BookNow isModal={false} /> */}
          <h2>{data.PHONE_NUMBER}</h2>
          <h2>{data.PHONE_NUMBER_2}</h2>
          <h4>{data.MAIL}</h4>
          <SocialLinks isCenter />
        </div>
      </div>
      {/* <input
        className={style.input}
        placeholder={getLang(lang, 'contact.name')}
      ></input>
      <input
        className={style.input}
        placeholder={getLang(lang, 'contact.mail')}
      ></input>
      <textarea
        className={style.input}
        style={{ height: '100px', padding: '15px 10px' }}
        placeholder={getLang(lang, 'contact.any.to.say')}
      ></textarea>
      <button
        className="btn-default hover-btn pointer"
        style={{
          fontFamily: 'var(--font-header)',
          color: 'var(--color-primary)',
          width: '100%',
        }}
      >
        {' '}
        {getLang(lang, 'contact.submit')}{' '}
      </button> */}
    </div>
  );
}

export default ContactRightPanel;
