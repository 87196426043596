import React from 'react';

import getLang from '../../utils/lang';

import Mozhi from '../../context/Mozhi';
import { useContext } from 'react';

function CTAButton({ setIsBookNowOpen }) {
  const { lang } = useContext(Mozhi);
  return (
    <div>
      <button
        className="btn-default pointer"
        style={{
          fontFamily: 'gilroy',
          padding: '15px',
          fontSize: '1.4em',
          color: 'aliceblue',
          background: 'rgba(112, 78, 225, 0.8)',
        }}
        onClick={() => setIsBookNowOpen(true)}
      >
        {getLang(lang, 'book.now')}{' '}
      </button>
    </div>
  );
}

export default CTAButton;
