import React from 'react';
import './Modal.css';

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <section className="modal-main" style={{ borderRadius: '20px' }}>
        {children}
        <button
          className="close btn-default pointer"
          style={{ padding: '17px' }}
          type="button"
          onClick={handleClose}
        >
          <img src="svg/cancel.svg" className="close-icon" alt="close"></img>
        </button>
      </section>
    </div>
  );
};

export default Modal;
