import React from 'react';
import data from '../../../data';
import getLang from '../../../utils/lang';
import CounterItem from '../CounterItem/CounterItem';

import Mozhi from '../../../context/Mozhi';
import { useContext } from 'react';
const { CAPACITY, DINING_HALL_SEATS, EVENTS_COUNT, SQFT } = data;

function CounterList() {
  const { lang } = useContext(Mozhi);

  const counts = [
    {
      number: CAPACITY,
      description: getLang(lang, 'mahal.capacity'),
    },
    {
      number: DINING_HALL_SEATS,
      description: getLang(lang, 'dining.hall.seats'),
    },
    {
      number: SQFT,
      description: getLang(lang, 'mahal.sqft'),
    },
    {
      number: EVENTS_COUNT,
      description: getLang(lang, 'mahal.events.successfully'),
    },
  ];
  return (
    <div>
      {counts.map((hex) => (
        <CounterItem {...hex} key={hex.description} />
      ))}
    </div>
  );
}

export default CounterList;
