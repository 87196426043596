import React from 'react';
import style from './Header.module.css';
import s from './MiniMenu.module.css';

function MiniMenu({ onClick }) {
  const handleIsActive = () => {
    onClick(true);
  };

  return (
    <button
      onClick={handleIsActive}
      className={`hover-btn pointer  btn-default btn-with-icon inline-block ${style.menu}  ${style.primary} ${s.container}`}
      style={{ top: '10px' }}
    >
      <img className={`${style.menuIcon}`} src="svg/menu.svg" alt={'menu'} />
    </button>
  );
}

export default MiniMenu;
