import React from 'react';
import ContactLeftPanel from '../ContactLeftPanel/ContactLeftPanel';
import ContactRightPanel from '../ContactRightPanel/ContactRightPanel';
import style from './ContactContainer.module.css';

function ContactContainer() {
  return (
    <div className={style.container}>
      <ContactLeftPanel />
      <ContactRightPanel />
    </div>
  );
}

export default ContactContainer;
