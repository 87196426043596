import React from 'react';
import getLang from '../../utils/lang';
import CTAButton from '../CTAButton/CTAButton';
import SocialLinks from '../SocialLinks/SocialLinks';

import style from './Main.module.css';

import Mozhi from '../../context/Mozhi';
import { useContext } from 'react';
import DeviceContext from '../../context/DeviceContext';
import { getDeviceStyle } from '../../utils';

function Main({ setIsBookNowOpen }) {
  const { lang } = useContext(Mozhi);
  const { isMobile, isMini, isTV, device } = useContext(DeviceContext);
  return (
    <div className={style.flexContainer}>
      <div className={style.centerContainer}>
        <div
          className={!(isMini || isMobile) ? 'btn-default' : null}
          style={{
            padding: isMobile ? '10px' : '50px',
            textAlign: isTV ? 'left' : 'center',
          }}
        >
          <span>
            <img
              src="img/company-logo.webp"
              className={style.logo}
              alt="logo"
            />
            <h1 id="main" className={style.title}>
              {getLang(lang, 'company.name')}
            </h1>
          </span>
          <p className={style.titleDesc}>
            {getLang(lang, 'company.description')}
          </p>
          <div>
            <div
              className=" inline-block"
              style={{ margin: '10px 10px 10px 0', padding: '5px' }}
            >
              <SocialLinks />
            </div>
            <CTAButton setIsBookNowOpen={setIsBookNowOpen} />
          </div>
        </div>
      </div>
      <div className={style.centerContainer}>
        <div className="btn-default">
          <iframe
            title="Youtube"
            width={getDeviceStyle(device, [300, 350, 400, 400, 560, 560], 'px')}
            height={getDeviceStyle(
              device,
              [200, 280, 300, 300, 315, 315],
              'px'
            )}
            src="https://www.youtube.com/embed/BQHf4uTGrAE?start=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            // allowfullscreen
            allowtransparency="true"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Main;
