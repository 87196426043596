import React from 'react';
import getLang from '../../utils/lang';
import CTAButton from '../CTAButton/CTAButton';

import Mozhi from '../../context/Mozhi';
import { useContext } from 'react';

import style from './CTAContainer.module.css';

function CTAContainer({ setIsBookNowOpen }) {
  const { lang } = useContext(Mozhi);
  return (
    <div className={style.container}>
      <h1>{getLang(lang, 'cta.text')} 😍</h1>
      <CTAButton setIsBookNowOpen={setIsBookNowOpen} />
    </div>
  );
}

export default CTAContainer;
