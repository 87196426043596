const dataToBeChanged = {
  CAPACITY: '250+',
  DINING_HALL_SEATS: '150+',
  SQFT: '2500',
  EVENTS_COUNT: '1+',
};

const stableData = {
  PHONE_NUMBER: 8438676575,
  PHONE_NUMBER_2: 9788172006,
  MAIL: 'shanmugadeivam@gmail.com',
};

const data = { ...dataToBeChanged, ...stableData };

export default data;
