const english = {
  // MAIN PAGE

  'company.name': 'Shanmuga Deivam Mahal',
  'company.description': 'Best place for your family events.',
  features: 'Features',
  aboutus: 'About us',
  events: 'Events',
  gallery: 'Gallery',
  contact: 'Contact',
  testimonials: 'Testimonials',
  'lang.change.button': 'தமிழில் காண',
  'book.now': 'Book now',
  menu: 'Menu',

  // FEATURES PAGE

  'features.desc':
    'All types of events can be booked here. From wedding to get together, from official meetup to party etc.',
  feature1: 'Surrounded by Trees',
  'feature1.desc':
    'The unique feature that makes our mahal feel special is the nature environment with trees and pure air.',
  feature2: 'Feel the Nature',
  'feature2.desc':
    'Located away from the urban town so no pollution, no dense traffic and hence no worries. But not too far for ease of transport. ',
  feature3: 'Outdoor Ceremony',
  'feature3.desc':
    'Ever dreamt of having a event as a outdoor ceremony? We have open stage for receptions, parties and even Desert dining and more...',
  feature4: 'Good parking space',
  'feature4.desc':
    'We have our own sophisticated place for car and bike parking. Where there won’t be any fuss in that.',
  feature5: 'Cose Effective',
  'feature5.desc':
    'We’d made the plans affordable for everyone to get benefitted.',
  feature6: 'And many more...',
  'feature6.desc': 'Feel free to make a visit and experience our environment.',

  // COUNTS PAGE
  'count.header': 'Some count that matters',
  'count.desc': 'Our achievement in the journey depicted in numbers',
  'mahal.capacity': 'Mahal Capacity',
  'dining.hall.seats': 'Dining hall seats',
  'mahal.sqft': 'Mahal Dimensions (Sqft)',
  'mahal.events.successfully': 'Events done successfully',

  // TYPES OF EVENTS PAGE
  'event.heading': 'Multi-purpose mahal',
  'event.wedding': 'Wedding and other traditional events',
  'event.wedding.desc': 'Reception, Engagement',
  'event.party': 'Party',
  'event.party.desc': 'Birthdays, Get togethers, Farewell, Corporate party',
  'event.business.fairs': 'Business fairs',
  'event.business.fairs.desc': 'Book fair, Furniture fair, Electronics fair',
  'event.business.events': 'Business events',
  'event.business.events.desc': 'Meetup, Seminars, Conference',
  'event.dining': 'Premium dining',
  'event.dining.desc': 'A well organized dining with fun events and DJ.',

  // CONTACTS PAGE
  'contact.name': 'Name',
  'contact.mail': 'Email ID',
  'contact.any.to.say': 'Anything to say...',
  'contact.submit': 'Submit',
  'cta.text': `Let's make your event a memorable one!`,

  location: 'Location',
  'contact.us': 'Contact us',
  documentTitle: 'Shanmuga Deivam Mahal',
};

export default english;
