import React from 'react';
import getLang from '../../../utils/lang';
import FeaturesList from '../FeaturesList/FeaturesList';

import Mozhi from '../../../context/Mozhi';
import { useContext } from 'react';

function FeaturesContent() {
  const { lang } = useContext(Mozhi);
  return (
    <div>
      <h1 id="features" style={{ marginBottom: '20px' }}>
        {getLang(lang, 'features')}
      </h1>
      <p className="title-desc">{getLang(lang, 'features.desc')}</p>
      <FeaturesList />
    </div>
  );
}

export default FeaturesContent;
