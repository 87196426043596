import React from 'react';
import Mozhi from '../../../context/Mozhi';
import { useContext } from 'react';
import getLang from '../../../utils/lang';
import style from './ContactLeftPanel.module.css';

function ContactLeftPanel() {
  const { lang } = useContext(Mozhi);
  return (
    <div className={style.container}>
      <h1 id="location">{getLang(lang, 'location')}</h1>
      <div
        id="map"
        className="map btn-default"
        style={{ backgroundColor: 'aliceblue', height: '450px' }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d982.4564245428045!2d78.06920528302881!3d9.94845604329612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c9fc54745fd5%3A0xdcec6d365e067b81!2sShanmuga%20Deivam%20Mahal!5e0!3m2!1sen!2sin!4v1616161126302!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          // allowfullscreen=""
          loading="lazy"
          title="Location"
        ></iframe>
      </div>
    </div>
  );
}

export default ContactLeftPanel;
