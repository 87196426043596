import { useState, useEffect } from 'react';
import { debounce } from '../utils';

// Hook
export function useDevice() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', debounce(handleResize, 200));

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const { width } = windowSize;
  let isMobile, isTab, isLaptop, isDesktop, isTV, isMini, device;
  if (width > 1600) {
    isTV = true;
    device = 'tv';
  } else if (width > 1024) {
    isDesktop = true;
    device = 'desktop';
  } else if (width > 768) {
    isLaptop = true;
    device = 'laptop';
  } else if (width > 480) {
    isTab = true;
    device = 'tab';
  } else if (width > 399) {
    isMobile = true;
    device = 'mobile';
  } else {
    isMini = true;
    device = 'mini';
  }

  return {
    ...windowSize,
    isMobile,
    isTab,
    isLaptop,
    isDesktop,
    isTV,
    isMini,
    device,
  };
}
