import React from 'react';
import CounterHeader from '../CounterHeader/CounterHeader';
import CounterList from '../CounterList/CounterList';

function CounterContainer() {
  return (
    <div
      style={{
        margin: '20px auto',
        textAlign: 'center',
        padding: '80px 0px',
      }}
    >
      <CounterHeader />
      <CounterList />
    </div>
  );
}

export default CounterContainer;
