import React from 'react';

import style from './FeaturesItems.module.css';

function FeaturesItems({ icon, heading, description }) {
  return (
    <div className={style.container}>
      <img
        src={icon}
        alt={heading}
        className={style.img + ' btn-default'}
      ></img>
      <h2 className={style.title}>{heading}</h2>
      <p className={style.desc}>{description}</p>
    </div>
  );
}

export default FeaturesItems;
