import React from 'react';

import getLang from '../../../utils/lang';

import Mozhi from '../../../context/Mozhi';
import { useContext } from 'react';

function EventsHeader() {
  const { lang } = useContext(Mozhi);
  return (
    <div>
      <h1 id="events">{getLang(lang, 'event.heading')}</h1>
    </div>
  );
}

export default EventsHeader;
