import { useState, useEffect } from 'react';
import Main from './components/Main/Main';
import { useDevice } from './hooks/useDevice';
import DeviceContext from './context/DeviceContext';
import Mozhi from './context/Mozhi';
import FeaturesContainer from './components/Features/FeaturesContainer/FeaturesContainer';
import CounterContainer from './components/Counter/CounterContainer/CounterContainer';
import EventsContainer from './components/Events/EventsContainer/EventsContainer';
import CTAContainer from './components/CTAContainer/CTAContainer';
import FooterContainer from './components/Footer/FooterContainer';
import ContactContainer from './components/Contact/ContactContainer/ContactContainer';
import style from './App.module.css';
import getLang from './utils/lang';
import BookNow from './components/Contact/BookNow/BookNow';
import Modal from './components/Modal/Modal';
import { getDeviceStyle } from './utils';
import Header from './components/Header/Header';
import MiniMenu from './components/Header/MiniMenu';
import HeaderModal from './components/Header/HeaderModal';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang');

  const deviceObj = useDevice();
  const { device, isMini, isMobile, isTab } = deviceObj;

  const [isEng, setLang] = useState(lang !== 'ta');
  const [isBookNowOpen, setIsBookNowOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isSmallMenu = isMini || isMobile || isTab;

  const setLanguage = (lang) => {
    setLang(lang);
    const newLang = lang(isEng) ? 'en' : 'ta';
    let newUrl;

    if (urlParams.has('lang')) {
      urlParams.set('lang', newLang);
      newUrl = `${window.location.origin}?${urlParams.toString()}`;
    } else {
      newUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?lang=' +
        newLang;
    }
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  useEffect(() => {
    const root = document.querySelector(':root');
    let header, primary, light;
    if (isEng) {
      header = 'gilroy';
      primary = 'Fira Sans';
      light = 'gilroy-light';
    } else {
      header = 'Catamaran';
      primary = 'Catamaran';
      light = 'Catamaran';
    }

    root.style.setProperty('--font-primary', primary);
    root.style.setProperty('--font-light', light);
    root.style.setProperty('--font-header', header);
  }, [isEng]);

  useEffect(() => {
    document.title = getLang(isEng ? 'en' : 'ta', 'documentTitle');
  }, [isEng]);

  useEffect(() => {
    const root = document.querySelector(':root');
    root.style.setProperty(
      '--h1-size',
      getDeviceStyle(device, [32, 36, 40, 44, 48, 54], 'px')
    );
    root.style.setProperty(
      '--h2-size',
      getDeviceStyle(device, [24, 26, 28, 30, 32, 34], 'px')
    );
    root.style.setProperty(
      '--h3-size',
      getDeviceStyle(device, [20, 21, 22, 23, 24, 24], 'px')
    );
    root.style.setProperty(
      '--h4-size',
      getDeviceStyle(device, [18, 19, 20, 21, 21, 21], 'px')
    );
    root.style.setProperty(
      '--p-size',
      getDeviceStyle(device, [12, 14, 16, 16, 16], 'px')
    );
  }, [device]);

  const handleIsMenuOpen = () => setIsMenuOpen(true);

  return (
    <DeviceContext.Provider value={deviceObj}>
      <Mozhi.Provider
        value={{ setLang: setLanguage, lang: isEng ? 'en' : 'ta' }}
      >
        <div className="App">
          {/* Modal */}
          {!isSmallMenu && <Header setIsBookNowOpen={setIsBookNowOpen} />}
          {isSmallMenu && <MiniMenu onClick={handleIsMenuOpen} />}
          {isSmallMenu && isMenuOpen && (
            <Modal show={isMenuOpen} handleClose={() => setIsMenuOpen(false)}>
              <HeaderModal
                setIsBookNowOpen={setIsBookNowOpen}
                setIsMenuOpen={setIsMenuOpen}
              />
            </Modal>
          )}
          <Main setIsBookNowOpen={setIsBookNowOpen} />
          <div className={style.container}>
            <div className={`${style.featuresContainer}`}>
              <FeaturesContainer />
            </div>
            <div>
              <CounterContainer />
            </div>
            <div>
              <EventsContainer />
            </div>

            <div style={{ margin: '50px 0' }}>
              <ContactContainer />
            </div>

            <div>
              <CTAContainer setIsBookNowOpen={setIsBookNowOpen} />
            </div>
          </div>
          <Modal
            show={isBookNowOpen}
            handleClose={() => setIsBookNowOpen(false)}
          >
            <BookNow />
          </Modal>
          <div>
            <FooterContainer />
          </div>
        </div>
      </Mozhi.Provider>
    </DeviceContext.Provider>
  );
}

export default App;
