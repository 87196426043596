import React, { useState, useContext } from 'react';
import getLang from '../../utils/lang';

import HeaderList from '../HeaderList/HeaderList';
import Mozhi from '../../context/Mozhi';

import style from './Header.module.css';

function Header({ setIsBookNowOpen }) {
  const { lang, setLang } = useContext(Mozhi);

  const [isActive, setIsActive] = useState(false);

  const handleIsActive = () => {
    setIsActive((preState) => !preState);
  };

  // events
  const handleLangChange = () => {
    setLang((pre) => !pre);
  };

  return (
    <div className={`${style.header}`}>
      {/* Menu list */}
      {isActive && <HeaderList />}

      {/* Menu */}
      <button
        onClick={handleIsActive}
        className={`${
          isActive ? style.active : 'btn-default'
        } hover-btn btn-with-icon inline-block pointer ${style.menu}  ${
          style.primary
        } `}
        style={{ height: '100%' }}
      >
        <img
          className={`${style.menuIcon}`}
          src="svg/menu.svg"
          alt={getLang(lang, 'menu')}
        />
        <span className={`${style.menuText}`}>{getLang(lang, 'menu')}</span>
      </button>

      {/* Language */}
      <button
        className={`btn-default inline-block pointer ${style.lang}`}
        onClick={handleLangChange}
      >
        {getLang(lang, 'lang.change.button')}
      </button>

      {/* CTA */}
      <button
        className={`btn-default inline-block pointer ${style.secondary} ${style.bookNow}`}
        onClick={() => setIsBookNowOpen(true)}
      >
        {getLang(lang, 'book.now')}
      </button>
    </div>
  );
}

export default Header;
