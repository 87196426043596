import React from 'react';

import style from './CounterItem.module.css';

function CounterItem(props) {
  const { number, description } = props;

  return (
    <div className={style.hexContainer}>
      <div className={style.hexagon}>
        <span style={{ borderRadius: '5px' }}>
          <div className={style.wanda}>
            <h1
              className={style.h}
              style={{ margin: '0px', fontFamily: 'gilroy' }}
            >
              {number}
            </h1>
            <p className={style.p} style={{ padding: '0px 10px' }}>
              {description}
            </p>
          </div>
        </span>
      </div>
    </div>
  );
}

export default CounterItem;
