import React from 'react';
import getLang from '../../../utils/lang';
import EventsItem from '../EventsItem/EventsItem';
import style from './EventsList.module.css';

import Mozhi from '../../../context/Mozhi';
import DeviceContext from '../../../context/DeviceContext';
import { useContext } from 'react';

const path = '/svg/';
const ext = '.svg';

function EventsList() {
  const { lang } = useContext(Mozhi);
  const device = useContext(DeviceContext);
  const { isMobile, isMini, isTab } = device;

  const isMinDevice = isMobile || isMini || isTab;

  const events = [
    {
      src: `${path}wedding${ext}`,
      heading: getLang(lang, 'event.wedding'),
      description: getLang(lang, 'event.wedding.desc'),
      gridArr: [1, 2, 1, 3],
      gridMobileArr: [1, 2, 1, 3],
      backgroundPosition: '40% 100%',
    },
    {
      src: `${path}party${ext}`,
      heading: getLang(lang, 'event.party'),
      description: getLang(lang, 'event.party.desc'),
      gridArr: [2, 4, 1, 2],
      gridMobileArr: [2, 3, 1, 3],
      backgroundPosition: '100%',
    },
    {
      src: `${path}conference${ext}`,
      heading: getLang(lang, 'event.business.events'),
      description: getLang(lang, 'event.business.events.desc'),
      gridArr: [2, 3, 2, 3],
      gridMobileArr: [1, 3, 3, 4],
      backgroundPosition: '300px',
    },
    {
      src: `${path}business-fair${ext}`,
      heading: getLang(lang, 'event.business.fairs'),
      description: getLang(lang, 'event.business.fairs.desc'),
      gridArr: [1, 3, 3, 5],
      gridMobileArr: [1, 2, 4, 6],
      backgroundPosition: '40% 70%',
    },

    {
      src: `${path}dining${ext}`,
      heading: getLang(lang, 'event.dining'),
      description: getLang(lang, 'event.dining.desc'),
      gridArr: [3, 4, 2, 5],
      gridMobileArr: [2, 3, 4, 6],
      backgroundPosition: '40% 70%',
    },
  ];
  return (
    <div className={style.container}>
      {events.map((event) => (
        <EventsItem {...event} key={event.src} isMinDevice={isMinDevice} />
      ))}
    </div>
  );
}

export default EventsList;
