import React from 'react';
import style from './EventsItem.module.css';

function EventsItem({
  src,
  heading,
  description,
  gridArr,
  backgroundPosition,
  isMinDevice,
  gridMobileArr,
}) {
  const [a, b, c, d] = isMinDevice ? gridMobileArr : gridArr || [];
  return (
    <div
      className={`${style.container} btn-default ${
        src.includes('conference') ? style.business : ''
      }`}
      style={{
        gridColumnStart: a,
        gridColumnEnd: b,
        gridRowStart: c,
        gridRowEnd: d,

        background: `url('${document.location.origin}${src}') no-repeat`,
        backgroundSize: 'contain',
        backgroundPosition: src.includes('conference')
          ? undefined
          : backgroundPosition,
        backgroundRepeat: 'no-repeat',
        // border: '2px solid crimson',
      }}
    >
      <div
        className={`${style.content}`}
        style={{
          margin: '10px',
          padding: '10px',
          backgroundColor: 'transparent',
        }}
      >
        <h3 className={style.h} color={'white'}>
          {heading}
        </h3>
        <p className={style.p} color={'white'}>
          {description}
        </p>
      </div>
    </div>
  );
}

export default EventsItem;
