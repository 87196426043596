import React from 'react';
import getLang from '../../../utils/lang';

import Mozhi from '../../../context/Mozhi';
import { useContext } from 'react';

function CounterHeader() {
  const { lang } = useContext(Mozhi);
  return (
    <div>
      <h1>{getLang(lang, 'count.header')}</h1>
    </div>
  );
}

export default CounterHeader;
